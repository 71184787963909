import type { ReactNode } from 'react';
import CookieConsent from 'react-cookie-consent';
import {
  GrFacebookOption,
  GrLinkedinOption,
  GrTwitter,
  GrYoutube,
} from 'react-icons/gr';

import Footer from '@/components/Footer/Footer';
import { Nav } from '@/components/Nav/Nav';
import type { FooterModel } from '@/types/footer.model';
import type { NavigationModel } from '@/types/navigation.model';

import distributiveLogoUrl from '@/assets/svg/distributive-logo.svg';

const navigationMenu: NavigationModel = {
  items: [
    {
      title: 'DCP PLATFORM',
      url: '/platform',
      subItems: [
        {
          title: 'Platform Overview',
          url: '/platform',
        },
        {
          title: 'DCP Workers',
          url: '/workers',
        },
      ],
    },
    {
      title: 'SOLUTIONS',
      url: '#',
      subItems: [
        {
          title: 'DIANA',
          url: '/diana',
        },
        {
          title: 'Osler',
          url: '/osler',
        },
        {
          title: 'Legendre Pairs',
          url: '/lp117',
        },
      ],
    },
    {
      title: 'ABOUT',
      url: '/about',
      subItems: [
        {
          title: 'About',
          url: '/about',
        },
        {
          title: 'Blog',
          url: '/blog',
        },
        {
          title: 'Careers',
          url: '/careers',
        },
        {
          title: 'Privacy Policy',
          url: 'https://dcp.cloud/privacy-policy/',
        },
        {
          title: 'News Releases',
          url: '/news',
        },
        {
          title: 'Media Mentions',
          url: '/media',
        },
        {
          title: 'Contact',
          url: '/contact',
        },
      ],
    },
  ],
};

const footerContent: FooterModel = {
  logo: {
    icon: distributiveLogoUrl,
    alt: 'Distributive logo',
    url: 'https://www.dribbble.com/dribbble',
  },
  socialIcons: [
    {
      icon: <GrLinkedinOption className="social-media-icon" />,
      url: 'https://www.linkedin.com/company/distributive',
      target: '_blank',
    },
    {
      icon: <GrFacebookOption className="social-media-icon" />,
      url: 'https://www.facebook.com/DistributiveNetwork',
      target: '_blank',
    },
    {
      icon: <GrTwitter className="social-media-icon" />,
      url: 'https://twitter.com/distributivenet',
      target: '_blank',
    },
    {
      icon: <GrYoutube className="social-media-icon" />,
      url: 'https://www.youtube.com/@kingsdistributedsystems847/featured',
      target: '_blank',
    },
  ],
  rights: `&copy; Distributive ${new Date().getFullYear()}. All Rights Reserved.`,
};

type MainLayoutProps = {
  children: ReactNode;
};

export function MainLayout({ children }: MainLayoutProps): JSX.Element {
  return (
    <>
      <Nav items={navigationMenu.items} />
      {children}
      <Footer
        logo={footerContent.logo}
        rights={footerContent.rights}
        policy={footerContent.policy}
        terms={footerContent.terms}
        socialIcons={footerContent.socialIcons}
        navigationMenu={navigationMenu.items}
      />
      <CookieConsent
        expires={150}
        location="bottom"
        buttonText="I Accept"
        cookieName="acceptCookies"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxHeight: '100%',
          fontFamily: 'Proxima Nova',
          background: 'rgba(0, 0, 0, 0.9)',
          color: 'rgba(255, 255, 255, 0.8)',
        }}
        buttonStyle={{
          background: 'rgba(0, 114, 198, 0.8)',
          color: 'rgba(255, 255, 255, 0.8)',
          border: '1px solid rgba(255, 255, 255, 0)',
          fontFamily: 'Proxima Nova',
        }}
      >
        This website uses cookies to ensure you get the best experience on our
        website.
        <a
          style={{
            color: 'rgba(255, 255, 255, 0.8)',
            fontFamily: 'Proxima Nova',
          }}
          aria-label="learn more about cookies"
          href="https://www.cookiesandyou.com"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          &nbsp;Learn more
        </a>
      </CookieConsent>
    </>
  );
}
